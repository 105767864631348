import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import classnames from 'classnames'

import downtown from '../../images/downtown-dos.png'
import theHubLogo from '../../images/thehub.jpg'
import theValleLogo from '../../images/valle.png'

import styles from './style.module.css'

function Nav() {
  const [menuVisible, toggleMenuVisible] = useState(false)

  const data = useStaticQuery(graphql`
    query allDevelopmentsYaml {
      allDevelopmentsYaml {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  `)

  let pathname = typeof window !== 'undefined' ? window.location.pathname : ''
  const slug = pathname.split('/').slice(1)

  const developments = data.allDevelopmentsYaml.edges.map(
    ({ node: { id, slug, title } }) => ({ id, slug, title }),
  )

  return (
    <nav className={styles.nav}>
      <div className={styles.navContent}>
        {slug == 'downtown-club' && (
          <div className={styles.logoContainerHub}>
            <Link to="/">
              <img
                className={styles.logo}
                src={theHubLogo}
                alt="Downtown Club"
              />
            </Link>
          </div>
        )}
       
        {slug != 'downtown-club' && slug != 'valle'  && (
          <div className={styles.logoContainer}>
            <Link to="/">
              <img className={styles.logo} src={downtown} alt="Downtown" />
            </Link>
          </div>
        )}
        <div className={styles.navListContainer}>
          <button
            onClick={() => toggleMenuVisible((prev) => !prev)}
            className={styles.hamburgerMenu}
            type="button"
          >
            <hr className={styles.navhrone} />
            <hr className={styles.navhrtwo} />
            <hr className={styles.navhrtree} />
          </button>
          <ul
            className={classnames(styles.navList, {
              [styles.navListVisible]: menuVisible,
            })}
          >
            {/* {developments
              .sort(({ title }, { title: titleB }) => {
                if (titleB.includes('Reforma') && title.includes('Fe')) return 1
                if (title.includes('Reforma')) return -1
                if (titleB.includes('Reforma') && title.includes('The Hub'))
                  return 1
                if (title.includes('The Hub')) return -1
                if (title.includes('Fe')) return -1
                if (titleB.includes('Fe') && title.includes('Insurgentes'))
                  return 1
                if (title.includes('Insurgentes')) return -1
                return 0
              })
              .map((development) => (
                <li key={development.id} className={styles.navItem}>
                  <Link activeClassName="active" to={`/${development.slug}`}>
                    {development.title}
                  </Link>
                </li>
              ))} */}
            <li className={styles.navItem}>
              <Link activeClassName="active" to={'/reforma'}>
                Downtown Reforma
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClassName="active" to={'/downtown-club'}>
                DOWNTOWN CLUB REFORMA
              </Link>
            </li>
            
            <li className={styles.navItem}>
              <Link activeClassName="active" to={'/santa-fe'}>
                Downtown Santa Fe
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClassName="active" to={'/insurgentes'}>
                Downtown Insurgentes
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link activeClassName="active" to={'/one-o-one'}>
                One O One
              </Link>
            </li>
           
            <li className={styles.navItem}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://begrand.mx/"
              >
                Residencial
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Nav
